import React, {useEffect} from "react";// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
// import TeamSection from "./Sections/TeamSection.js";
// import WorkSection from "./Sections/WorkSection.js";
import makeStyles from "@material-ui/core/styles/makeStyles";


import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
import ContactSection from "../ServicesPage/Sections/WorkSection";
// import TeamSection from "./Sections/TeamSection";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function LandingPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "../../static/jss/head.js";
        script.async = true;

        document.head.appendChild(script);
    }, []);

    return (
        <div>
            <Header
                brand={<div>Elderberry Mgmt™</div>}
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 20,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("../../assets/img/landing-bg.jpg")}>
                <div className={classes.container}>
                    <div className="spacer-100 hide-mobile"> </div>
                    <GridContainer>
                        {/*<div className="spacer-200 hide-mobile"> </div>*/}
                        {/*<div className="spacer-200 hide-mobile"> </div>*/}
                        <GridItem xs={12} sm={12} md={12} lg={6}>
                            <h1 className={classes.title + " hide-mobile"}>
                                Build anything.</h1>
                            <h4 className="hide-mobile">
                                Elderberry Management™ provides software engineering and business management
                                services to make your company more efficient, more profitable, and infinitely scalable.
                                {/*<span className="hide-mobile">*/}
                                {/*    <br/>*/}
                                {/*    <br/>*/}
                                {/*    We provide a range of software development services including API development, front-end*/}
                                {/*    development and UX design, websites and web applications, machine learning and AI, GIS and mapping solutions, native iOS and android app development, and analytics.<br/>*/}
                                {/*    <br/>*/}
                                {/*    Whatever your software development needs are Elderberry Management fulfills them*/}
                                {/*    with a focus on driving your KPIs and ROI.*/}
                                {/*</span>*/}
                            </h4>
                            <br/>
                            <Button
                                size="lg"
                                href="#contact-us"
                                // target="_blank"
                                rel="noopener noreferrer"
                                className="gold hide-mobile"
                            >
                                {/*<i className="fas fa-play" />*/}
                                Learn more
                            </Button>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className="center hide show-mobile">
                                <h1 className={classes.title + " center"}>Build anything.</h1>
                                <h4>
                                    Elderberry Management™ provides software engineering and business management
                                    services to make your company more efficient, more profitable, and infinitely scalable.
                                    {/*<span className="hide-mobile center">*/}
                                    {/*<br/>*/}
                                        {/*<br/>*/}
                                        {/*Elderberry Management provides a range of software development and startup */}
                                        {/*services including product management, software engineering,*/}
                                        {/*API development, front-end development and UX design, websites and web */}
                                        {/*applications, GIS and mapping solutions, native iOS and android development, */}
                                        {/*and analytics.<br/>*/}
                                    {/*<br/>*/}
                                        {/*Whatever your software development needs are, Elderberry Management fulfills them*/}
                                        {/*with a focus on your ROI.*/}
                                {/*</span>*/}
                                </h4>
                                <br/>
                                {/*<Button*/}
                                {/*    size="lg"*/}
                                {/*    href="#contact-us"*/}
                                {/*    // target="_blank"*/}
                                {/*    rel="noopener noreferrer"*/}
                                {/*    className="gold"*/}
                                {/*>*/}
                                {/*    /!*<i className="fas fa-play" />*!/*/}
                                {/*    Learn more*/}
                                {/*</Button>*/}
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <ProductSection/>
                    {/*<TeamSection/>*/}
                    <ContactSection/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
