import React, {useEffect} from "react";
import classNames from "classnames";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
import RsvpForm from "../../components/RsvpForm/RsvpForm";


const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function RsvpPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "../../static/jss/head.js";
        script.async = true;

        document.head.appendChild(script);
    }, []);

    return (
        <div>
            <Header
                brand={<div>Elderberry Mgmt™</div>}
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 20,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("../../assets/img/landing-bg.jpg")}>
                <div className={classes.container}>
                    <div className="spacer-100 hide-mobile"> </div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={6}>
                            <h1 className={classes.title + " center"}>We hope to see you.</h1>
                            <h4 className="hide-mobile">
                                Please RSVP "yes" or "no" along with the number of guests attending.
                            </h4>
                            <div className="center hide show-mobile">
                                <h4>
                                    Please RSVP "yes" or "no" along with the number of guests attending.
                                </h4>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <RsvpForm/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
