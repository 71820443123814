import React, { Component } from 'react'
import * as emailjs from 'emailjs-com'
import FormGroup from "@material-ui/core/FormGroup";
import {Label} from "@material-ui/icons";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Form from "reactstrap/es/Form";
import Redirect from "react-router-dom/es/Redirect";

class RsvpForm extends Component {
    state = {
        name: '',
        email: '',
        message: '',
        redirect: false
    };
    handleSubmit(e) {
        e.preventDefault();
        const { email, message, name, redirect } = this.state;
        let templateParams = {
            from_name: name,
            from_email: email,
            to_name: '',
            message_html: message,
            redirect: redirect
        };
        emailjs.send(
            'gmail',
            'template_35lehqc',
            templateParams,
            'user_p58wyK1kED3U0ujyjbEIJ'
        );
        this.resetForm();
    }
    resetForm() {
        this.setState({
            name: '',
            email: '',
            message: '',
            redirect: true
        });
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value });
    };
    render() {
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to='/'/>;
        }

        return (
            <>
                <br/>
                <br/>
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <FormGroup controlid="formBasicEmail">
                            <Label className="text-muted">Email address</Label>
                            <Input
                                type="email"
                                name="email"
                                value={this.state.email}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, 'email')}
                                placeholder="Your email"
                                required={true}
                            />
                        </FormGroup>
                        <FormGroup controlid="formBasicName">
                            <Label className="text-muted">Name</Label>
                            <Input
                                type="text"
                                name="name"
                                value={this.state.name}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, 'name')}
                                placeholder="Your name"
                                required={true}
                            />
                        </FormGroup>
                        <FormGroup controlid="formBasicMessage">
                            <Label className="text-muted">Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                className="text-primary text-area"
                                value={this.state.message}
                                onChange={this.handleChange.bind(this, 'message')}
                                placeholder="Message"
                                multiline={true}
                                rows={5}
                                required={true}
                            />
                        </FormGroup>
                        <div className="space-75"><br/></div>
                        <Button
                            type="submit"
                            size="large"
                            className="gold"
                        >
                            Submit
                        </Button>
                    </Form>
                <br/>
                <br/>
            </>
        )
    }
}
export default RsvpForm
