/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
// import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";
//
import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";
// import GridContainer from "../Grid/GridContainer";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.centered}>
          {/*<List className={classes.list}>*/}
          {/*  <ListItem className={classes.inlineBlock}>*/}
          {/*    <a*/}
          {/*      href="/#contact"*/}
          {/*      className={classes.block}*/}
          {/*    >*/}
          {/*      Contact*/}
          {/*    </a>*/}
          {/*  </ListItem>*/}
          {/*  <ListItem className={classes.inlineBlock}>*/}
          {/*    <a*/}
          {/*      href="/"*/}
          {/*      className={classes.block}*/}
          {/*    >*/}
          {/*      About us*/}
          {/*    </a>*/}
          {/*  </ListItem>*/}
          {/*  /!*<ListItem className={classes.inlineBlock}>*!/*/}
          {/*  /!*  <a*!/*/}
          {/*  /!*    href="/blog"*!/*/}
          {/*  /!*    className={classes.block}*!/*/}
          {/*  /!*  >*!/*/}
          {/*  /!*    Blog*!/*/}
          {/*  /!*  </a>*!/*/}
          {/*  /!*</ListItem>*!/*/}
          {/*</List>*/}
        </div>
        <div>
          Call us: (408) 674-9804
          <div className="spacer-100"> </div>
        </div>
        <div className={classes.centered + " copyright"}>
          Copyright &copy; {1900 + new Date().getYear()} Elderberry Management
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
