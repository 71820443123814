import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch} from "react-router-dom";

import "./assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import Components from "./views/Components/Components.js";
import LandingPage from "./views/LandingPage/LandingPage.js";
import ProfilePage from "./views/ProfilePage/ProfilePage.js";
import LoginPage from "./views/LoginPage/LoginPage.js";
import PostList from "./components/WordPress/PostList";
import ServicesPage from "./views/ServicesPage/ServicesPage";
import RsvpPage from "./views/RsvpPage/RsvpPage";
import ParkingPage from "./views/ParkingPage/ParkingPage";

let hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route path="/services" component={ServicesPage}/>
            <Route path="/components" component={Components}/>
            <Route path="/profile-page" component={ProfilePage}/>
            <Route path="/parking" component={ParkingPage}/>
            <Route path="/login-page" component={LoginPage}/>
            <Route path="/posts" component={PostList}/>
            <Route path="/rsvp" component={RsvpPage}/>
            <Route path="/" component={LandingPage}/>
        </Switch>
    </Router>,
    document.getElementById("root")
);
