import React, {useEffect} from "react";
import classNames from "classnames";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import ContactSection from "./Sections/WorkSection.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";



const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function LandingPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "../../static/jss/head.js";
        script.async = true;

        document.head.appendChild(script);
    }, []);

    return (
        <div>
            <Header
                brand={<div>Elderberry Mgmt™</div>}
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 20,
                    color: "white"
                }}
                {...rest}
            />
            <div
                style={{
                    background: "linear-gradient(126deg, rgba(19,4,22,1) 0%, rgba(21,6,24,1) 15%, rgba(10,3,11,1) 100%)",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <div className="spacer-100"/>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <h1 className={classes.title + " center"}>Services</h1>
                            <div className={classes.container}>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={6} className="services-card">
                                        <Card>
                                            <CardHeader color="primary" className={classes.cardHeader}>
                                                <h4 color="white"><strong>&nbsp;&nbsp;PRODUCT</strong></h4>
                                            </CardHeader>
                                            <CardBody>
                                                <ul>
                                                    <li><h4>Product Discovery</h4></li>
                                                </ul>
                                                <ul>
                                                    <li><h4>Product Design & Development</h4></li>
                                                </ul>
                                                <ul>
                                                    <li><h4>Product Management</h4></li>
                                                </ul>
                                            </CardBody>
                                            <CardFooter className={classes.cardFooter}>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className="services-card">
                                        <Card>
                                            <CardHeader color="primary" className={classes.cardHeader}>
                                                <h4 color="white"><strong>&nbsp;&nbsp;SOFTWARE ENGINEERING</strong></h4>
                                            </CardHeader>
                                            <CardBody>
                                                <ul>
                                                    <li><h4>Accessibility Compliance (Section 508)</h4></li>
                                                </ul>
                                                <ul>
                                                    <li><h4>Software Engineering Contracting</h4></li>
                                                </ul>
                                                <ul>
                                                    <li><h4>Fractional CTO</h4></li>
                                                </ul>
                                            </CardBody>
                                            <CardFooter className={classes.cardFooter}>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </div>

                            <h2>How we build it</h2>
                            <h3>1. Free Consultation</h3>
                            <p>Book an appointment so we can understand your needs.</p>
                            <h3>2. Product Discovery</h3>
                            <p>We conduct research to fully understand the problem and make sure we build a solution
                                that fits your needs.</p>
                            <h3>3. Design Sprints</h3>
                            <p>Through a series of rapid iterations and testing, we build a product that fits your
                                budget and is validated through every step of the design process.</p>
                            <h3>4. Software Development</h3>
                            <p>Guided by our architects, every piece of code pushed by our software engineers meets
                                acceptance criteria.</p>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className="spacer-200"/>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <ContactSection/>
                    </div>
                </div>
                <Footer whiteFont/>
            </div>
        </div>
    );
}
