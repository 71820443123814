import React, { Component } from 'react'
import * as emailjs from 'emailjs-com'
import FormGroup from "@material-ui/core/FormGroup";
import {Label} from "@material-ui/icons";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Form from "reactstrap/es/Form";
// import CustomInput from "../CustomInput/CustomInput";

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        subject: '',
        message: '',
    };
    handleSubmit(e) {
        e.preventDefault();
        const { email, subject, message, name } = this.state;
        let templateParams = {
            from_name: name,
            from_email: email,
            to_name: '',
            subject: subject,
            message_html: message,
        };
        emailjs.send(
            'gmail',
            'template_dZSEvoqg',
            templateParams,
            'user_p58wyK1kED3U0ujyjbEIJ'
        );
        this.resetForm()
    }
    resetForm() {
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        })
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    };
    render() {
        return (
            <>
                    {/*<h1 className="p-heading1">Get in Touch</h1>*/}
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <FormGroup controlid="formBasicEmail">
                            <Label className="text-muted">Email address</Label>
                            <Input
                                type="email"
                                name="email"
                                value={this.state.email}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, 'email')}
                                placeholder="Your email"
                                required={true}
                            />
                        </FormGroup>
                        <FormGroup controlid="formBasicName">
                            <Label className="text-muted">Name</Label>
                            <Input
                                type="text"
                                name="name"
                                value={this.state.name}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, 'name')}
                                placeholder="Your name"
                                required={true}
                            />
                        </FormGroup>
                        <FormGroup controlid="formBasicSubject">
                            <Label className="text-muted">Subject</Label>
                            <Input
                                type="text"
                                name="subject"
                                className="text-primary"
                                value={this.state.subject}
                                onChange={this.handleChange.bind(this, 'subject')}
                                placeholder="Subject"
                            />
                        </FormGroup>
                        <FormGroup controlid="formBasicMessage">
                            <Label className="text-muted">Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                className="text-primary text-area"
                                value={this.state.message}
                                onChange={this.handleChange.bind(this, 'message')}
                                placeholder="Message"
                                multiline={true}
                                rows={5}
                                required={true}
                            />
                            {/*        <CustomInput*/}
                            {/*            labelText="Your Message"*/}
                            {/*            id="message"*/}
                            {/*            formControlProps={{*/}
                            {/*                fullWidth: true*/}
                            {/*            }}*/}
                            {/*            inputProps={{*/}
                            {/*                multiline: true,*/}
                            {/*                rows: 5*/}
                            {/*            }}*/}
                            {/*        />*/}
                        </FormGroup>
                        {/*<Button v type="submit">*/}
                        {/*    Submit*/}
                        {/*</Button>*/}
                        <div className="space-75"><br/></div>
                        <Button
                            type="submit"
                            size="large"
                            // href="/contact-success"
                            // target="_blank"
                            // rel="noopener noreferrer"
                            className="gold"
                        >
                            Submit
                        </Button>
                    </Form>
            </>
        )
    }
}
export default ContactForm
