import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// import {ContactForm} from "./components/ContactForm/ContactForm";

// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";

import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import ContactForm from "../../../components/ContactForm/ContactForm";

const useStyles = makeStyles(styles);

export default function WorkSection() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem cs={12} sm={12} md={8}>
                    <h2 id="contact-us" className={classes.title}>Chat with us</h2>
                    <h4 className={classes.description}>
                        Whether you are just beginning to explore an idea or are launching a new software development initiative, we are excited to hear from you.
                    </h4>
                    {/*<form>*/}
                    {/*    <GridContainer>*/}
                    {/*        <GridItem xs={12} sm={12} md={6}>*/}
                    {/*            <CustomInput*/}
                    {/*                labelText="Your Name"*/}
                    {/*                id="name"*/}
                    {/*                formControlProps={{*/}
                    {/*                    fullWidth: true*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </GridItem>*/}
                    {/*        <GridItem xs={12} sm={12} md={6}>*/}
                    {/*            <CustomInput*/}
                    {/*                labelText="Your Email"*/}
                    {/*                id="email"*/}
                    {/*                formControlProps={{*/}
                    {/*                    fullWidth: true*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </GridItem>*/}
                    {/*        <CustomInput*/}
                    {/*            labelText="Your Message"*/}
                    {/*            id="message"*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true,*/}
                    {/*                className: classes.textArea*/}
                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                multiline: true,*/}
                    {/*                rows: 5*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*        <form action="https://usebasin.com/f/45a90ed2b0f5" method="POST">*/}
                    {/*            <label htmlFor="name">Name</label>*/}
                    {/*            <input type="text" id="name" name="name" required />*/}
                    {/*            <label htmlFor="email-address">Email Address</label>*/}
                    {/*            <input type="email" id="email" name="email" required />*/}
                    {/*            <label htmlFor="email-address">Email Address</label>*/}
                    {/*            <input type="textarea" id="message" name="message" required />*/}
                    {/*            <button type="submit">Submit</button>*/}
                    {/*        </form>*/}
                    {/*        <GridContainer justify="center" className="center">*/}
                    {/*            <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>*/}
                    {/*                <Button className="gold left-margin-25">Send Message</Button>*/}
                    {/*            </GridItem>*/}
                    {/*        </GridContainer>*/}
                    {/*    </GridContainer>*/}
                    {/*</form>*/}
                    <ContactForm />
                </GridItem>
            </GridContainer>
        </div>
    );
}
