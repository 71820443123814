import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import HeaderLinks from "./HeaderLinks";
// import {ChatBubble, VpnKey} from "@material-ui/icons";


const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function SwipeableTemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            {/*<List>*/}
            {/*    {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (*/}
            {/*        <ListItem button key={text}>*/}
            {/*            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
            {/*            <ListItemText primary={text} />*/}
            {/*        </ListItem>*/}
            {/*    ))}*/}
            {/*</List>*/}
            {/*<Divider />*/}
            {/*<List>*/}
            {/*    {['All mail', 'Trash', 'Spam'].map((text, index) => (*/}
            {/*        <ListItem button key={text}>*/}
            {/*            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
            {/*            <ListItemText primary={text} />*/}
            {/*        </ListItem>*/}
            {/*    ))}*/}
            {/*</List>*/}

            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <Button
                        href="/services"
                        color="transparent"
                        className={classes.navLink}
                    >
                        Services
                        {/*<ChatBubble className={classes.icons}/>Contact*/}
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        href="/#contact-us"
                        color="transparent"
                        className={classes.navLink}
                    >
                        Contact
                        {/*<ChatBubble className={classes.icons}/>Contact*/}
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        href="/login-page"
                        color="transparent"
                        className={classes.navLink}
                    >
                        Customer Portal
                        {/*<VpnKey className={classes.icons}/> Customer Portal*/}
                    </Button>
                </ListItem>
            </List>
        </div>
    );

    // const fullList = side => (
    //     <div
    //         className={classes.fullList}
    //         role="presentation"
    //         onClick={toggleDrawer(side, false)}
    //         onKeyDown={toggleDrawer(side, false)}
    //     >
    //         {/*<List>*/}
    //         {/*    {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (*/}
    //         {/*        <ListItem button key={text}>*/}
    //         {/*            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
    //         {/*            <ListItemText primary={text} />*/}
    //         {/*        </ListItem>*/}
    //         {/*    ))}*/}
    //         {/*</List>*/}
    //         {/*<Divider />*/}
    //         {/*<List>*/}
    //         {/*    {['All mail', 'Trash', 'Spam'].map((text, index) => (*/}
    //         {/*        <ListItem button key={text}>*/}
    //         {/*            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
    //         {/*            <ListItemText primary={text} />*/}
    //         {/*        </ListItem>*/}
    //         {/*    ))}*/}
    //         {/*</List>*/}
    //         {/*<HeaderLinks/>*/}
    //         <List className={classes.list}>
    //             <ListItem className={classes.listItem}>
    //                 <Button
    //                     href="/#contact-us"
    //                     color="transparent"
    //                     className={classes.navLink}
    //                 >
    //                     <ChatBubble className={classes.icons}/>Contact
    //                 </Button>
    //             </ListItem>
    //             <ListItem className={classes.listItem}>
    //                 <Button
    //                     href="/login-page"
    //                     color="transparent"
    //                     className={classes.navLink}
    //                 >
    //                     <VpnKey className={classes.icons}/> Customer Portal
    //                 </Button>
    //             </ListItem>
    //         </List>
    //     </div>
    // );

    return (
        <div>
            <Button className="burger" onClick={toggleDrawer('right', true)}>
                {/*<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"*/}
                {/*     role="presentation">*/}
                    {/*<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>*/}
                {/*</svg>*/}
            </Button>
            {/*<Button onClick={toggleDrawer('right', true)}>Open Right</Button>*/}
            {/*<Button onClick={toggleDrawer('top', true)}>Open Top</Button>*/}
            {/*<Button onClick={toggleDrawer('bottom', true)}>Open Bottom</Button>*/}
            <SwipeableDrawer
                anchor="right"
                open={state.right}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
            >
                {sideList('right')}
            </SwipeableDrawer>
            {/*<SwipeableDrawer*/}
            {/*    anchor="top"*/}
            {/*    open={state.top}*/}
            {/*    onClose={toggleDrawer('top', false)}*/}
            {/*    onOpen={toggleDrawer('top', true)}*/}
            {/*>*/}
            {/*    {fullList('top')}*/}
            {/*</SwipeableDrawer>*/}
            {/*<SwipeableDrawer*/}
            {/*    anchor="bottom"*/}
            {/*    open={state.bottom}*/}
            {/*    onClose={toggleDrawer('bottom', false)}*/}
            {/*    onOpen={toggleDrawer('bottom', true)}*/}
            {/*>*/}
            {/*    {fullList('bottom')}*/}
            {/*</SwipeableDrawer>*/}
            {/*<SwipeableDrawer*/}
            {/*    anchor="right"*/}
            {/*    open={state.right}*/}
            {/*    onClose={toggleDrawer('right', false)}*/}
            {/*    onOpen={toggleDrawer('right', true)}*/}
            {/*>*/}
            {/*    {sideList('right')}*/}
            {/*</SwipeableDrawer>*/}

            {/*<List className={classes.list}>*/}
            {/*    <ListItem className={classes.listItem}>*/}
            {/*        <Button*/}
            {/*            href="/#contact-us"*/}
            {/*            color="transparent"*/}
            {/*            className={classes.navLink}*/}
            {/*        >*/}
            {/*            <ChatBubble className={classes.icons}/>Contact*/}
            {/*        </Button>*/}
            {/*    </ListItem>*/}
            {/*    <ListItem className={classes.listItem}>*/}
            {/*        <Button*/}
            {/*            href="/login-page"*/}
            {/*            color="transparent"*/}
            {/*            className={classes.navLink}*/}
            {/*        >*/}
            {/*            <VpnKey className={classes.icons}/> Customer Portal*/}
            {/*        </Button>*/}
            {/*    </ListItem>*/}
            {/*</List>*/}
        </div>
    );
}
