import React, {useState, useEffect} from "react";
import classNames from "classnames";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";


const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function ParkingPage(props) {
    const classes = useStyles();
    const {...rest} = props;
    const {height} = useWindowDimensions();

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "../../static/jss/head.js";
        script.async = true;

        document.head.appendChild(script);
    }, []);

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowDimensions;
    }

    return (
        <div>
            <Header
                brand={<div>Elderberry Mgmt™</div>}
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 20,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("../../assets/img/landing-bg.jpg")}>
                <div className={classes.container}>
                    {/*<div className="spacer-100 hide-mobile"></div>*/}
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={6}>
                            <div style={{padding: "10px"}}>
                                <h1 className={classes.title + " center"}>See you soon!</h1>
                                <h4><a
                                    href="https://www.google.com/maps/place/1614+Wolf+Trail+Rd,+Ballwin,+MO+63021/@38.5488563,-90.6043448,17z/data=!3m1!4b1!4m5!3m4!1s0x87d929e0323e36db:0x6a40981080944367!8m2!3d38.5488563!4d-90.6021561"
                                    target="_blank" rel="noopener noreferrer" style={{color: "#0096FF"}}>Get driving directions to 1614 Wolf Trail
                                    Road, Wildwood, MO 63021</a>
                                </h4>
                                <h4>
                                    Please park at one of the parking spots designated on the map below. If you need
                                    help, call
                                    Sarah at <a href="tel:3149564437" style={{color: "#0096FF"}}>(314) 956-4437</a>.
                                </h4>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                {/*<div className={classes.container}>*/}
                <iframe
                    src="https://extant.maps.arcgis.com/apps/webappviewer/index.html?id=cdba6da2798b4ceeb53d92be7c7fd458"
                    title="Parking Map"
                    allowFullScreen="true" width="100%" height={height}
                    allow="geolocation"/>
                {/*</div>*/}
            </div>
            <Footer/>
        </div>
    );
}
