// import {makeStyles} from "@material-ui/core";
// import styles from "../../assets/jss/material-kit-react/views/loginPage";
import React, {Component} from "react";
// import Header from "../Header/Header";
// import HeaderLinks from "../Header/HeaderLinks";
// import image from "../../assets/img/bg7.jpg";
import GridContainer from "../Grid/GridContainer";
// import GridItem from "../Grid/GridItem";
import {Link} from "react-router-dom";
import axios from "axios";
import Header from "../Header/Header";
import HeaderLinks from "../Header/HeaderLinks";
import Parallax from "../Parallax/Parallax";
// import Button from "../CustomButtons/Button";
import classNames from "classnames";
// import ProductSection from "../../views/LandingPage/Sections/ProductSection";
// import ContactSection from "../../views/LandingPage/Sections/ContactSection";
import Footer from "../Footer/Footer";
import {makeStyles} from "@material-ui/styles";
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
import Card from "../Card/Card";

const classes = makeStyles(styles);
const dashboardRoutes = [];

export default class PostList extends Component {

    useStyles;
    classes;
    // dashboardRoutes;
    // state;
    // rest;

    constructor(props) {
        super(props);
        this.state = {
            posts: []
        };
        this.createMarkup = this.createMarkup.bind();
    }

    componentDidMount() {
        return axios.get(`https://cms.lucrelabs.com/wp-json/wp/v2/posts/`)
            .then((posts) => {
                this.setState({posts: posts.data});
                // TODO remove this console log
                console.log("posts: " + JSON.stringify(posts.data));
            })
            .catch((error) => {
                console.error(error);
            });
    }

    createMarkup(html) {
        return {__html: html};
    }

    render() {
        function renderBgImage(post) {
            return {
                width: "100%",
                height: "200px",
                backgroundImage: "url(" + post.jetpack_featured_media_url + ")",
                backgroundSize: "cover",
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }
        }

        function fullwidthMargin() {
            return {
                margin: "25px"
            }

        }

        function cardHeaderStyle() {
            return {
                paddingLeft: "25px",
                paddingRight: "25px"
            }
        }

        function cardBodyStyle() {
            return {
                padding: "25px 25px 5px 25px"
            }
        }

        return (
            <div>
                <Header
                    brand={<div>Elderberry Mgmt™</div>}
                    color="transparent"
                    routes={dashboardRoutes}
                    rightLinks={<HeaderLinks/>}
                    fixed
                    changeColorOnScroll={{
                        height: 20,
                        color: "white"
                    }}
                />
                <Parallax filter image={require("../../assets/img/profile-bg.jpg")} style={{maxHeight: "400px"}}>
                    <div className={classes.container}>
                        {/*<div className="spacer-200 hide-mobile"></div>*/}
                        <GridContainer>
                            {/*<GridItem xs={12} sm={12} md={7} lg={6}>*/}
                            {/*    <h1 className={classes.title + " hide-mobile"}>Build anything. Automate all of it.</h1>*/}
                            {/*    <h4 className="hide-mobile">*/}
                            {/*        <strong>LUCRE</strong>labs™ creates and supports applications to automate your*/}
                            {/*        business*/}
                            {/*        systems -- making them more efficient, more profitable, and infinitely scalable.*/}
                            {/*        <span className="hide-mobile">*/}
                            {/*        <br/>*/}
                            {/*        <br/>*/}
                            {/*        We provide a range of software development services including API development, front-end*/}
                            {/*        development and UX design, websites and web applications, machine learning and AI, GIS and mapping solutions, native iOS and android app development, and analytics.<br/>*/}
                            {/*        <br/>*/}
                            {/*        Whatever your software development needs are <strong>LUCRE</strong>labs fulfills them*/}
                            {/*        with a focus on driving your KPIs and ROI.*/}
                            {/*    </span>*/}
                            {/*    </h4>*/}
                            {/*    <br/>*/}
                            {/*    <Button*/}
                            {/*        size="lg"*/}
                            {/*        href="#contact-us"*/}
                            {/*        // target="_blank"*/}
                            {/*        rel="noopener noreferrer"*/}
                            {/*        className="gold hide-mobile"*/}
                            {/*    >*/}
                            {/*        /!*<i className="fas fa-play" />*!/*/}
                            {/*        Learn more*/}
                            {/*    </Button>*/}
                            {/*    <div className="center hide show-mobile">*/}
                            {/*        <h1 className={classes.title + " center"}>Build anything.</h1>*/}
                            {/*        <h4>*/}
                            {/*            <strong>LUCRE</strong>labs™ creates and supports software to make your*/}
                            {/*            business more efficient, more profitable, and infinitely scalable.*/}
                            {/*            <span className="hide-mobile center">*/}
                            {/*        <br/>*/}
                            {/*        <br/>*/}
                            {/*        We provide a range of software development services including API development, front-end*/}
                            {/*        development and UX design, websites and web applications, GIS and mapping solutions,*/}
                            {/*        native iOS and android development, and analytics.<br/>*/}
                            {/*        <br/>*/}
                            {/*        Whatever your software development needs are <strong>LUCRE</strong>labs fulfills them*/}
                            {/*        with a focus on your ROI.*/}
                            {/*    </span>*/}
                            {/*        </h4>*/}
                            {/*        <br/>*/}
                            {/*        <Button*/}
                            {/*            size="lg"*/}
                            {/*            href="#contact-us"*/}
                            {/*            // target="_blank"*/}
                            {/*            rel="noopener noreferrer"*/}
                            {/*            className="gold"*/}
                            {/*        >*/}
                            {/*            /!*<i className="fas fa-play" />*!/*/}
                            {/*            Learn more*/}
                            {/*        </Button>*/}
                            {/*        <br/>*/}
                            {/*        <br/>*/}
                            {/*        <br/>*/}
                            {/*    </div>*/}
                            {/*</GridItem>*/}
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classNames(classes.container)} style={fullwidthMargin()}>
                        {/*<ProductSection/>*/}
                        {/* the actual PostList ;) */}
                        {this.state.posts.map(post => (
                            <Link to={`/${post.slug}`} key={post.id}>
                                <Card key={post.id}>
                                    <div className="card-content">
                                        <div style={cardHeaderStyle()}>
                                            <h3>{post.title.rendered}</h3>
                                        </div>
                                        <div style={renderBgImage(post)}/>
                                        <div style={cardBodyStyle()} dangerouslySetInnerHTML={this.createMarkup(post.excerpt.rendered)}/>
                                    </div>
                                </Card>
                            </Link>
                        ))}
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}
