import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import People from "@material-ui/icons/People";
// core components
import Header from "../../components/Header/Header.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";

import styles from "../../assets/jss/material-kit-react/views/loginPage.js";

import image from "../../assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function () {
        setCardAnimation("");
    }, 700);
    const classes = useStyles();
    const {...rest} = props;
    return (
        <div>
            <Header
                absolute
                color="transparent"
                brand={<div>Elderberry Mgmt</div>}
                rightLinks={<HeaderLinks/>}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4} className="login-card">
                            <Card className={classes[cardAnimaton]}>
                                <form className={classes.form}>
                                    <CardHeader color="primary" className={classes.cardHeader}>
                                        <h4 color="white">Customer Portal</h4>
                                        {/*<div className={classes.socialLine}>*/}
                                        {/*  <Button*/}
                                        {/*    justIcon*/}
                                        {/*    href="#pablo"*/}
                                        {/*    target="_blank"*/}
                                        {/*    color="transparent"*/}
                                        {/*    onClick={e => e.preventDefault()}*/}
                                        {/*  >*/}
                                        {/*    <i className={"fab fa-twitter"} />*/}
                                        {/*  </Button>*/}
                                        {/*  <Button*/}
                                        {/*    justIcon*/}
                                        {/*    href="#pablo"*/}
                                        {/*    target="_blank"*/}
                                        {/*    color="transparent"*/}
                                        {/*    onClick={e => e.preventDefault()}*/}
                                        {/*  >*/}
                                        {/*    <i className={"fab fa-facebook"} />*/}
                                        {/*  </Button>*/}
                                        {/*  <Button*/}
                                        {/*    justIcon*/}
                                        {/*    href="#pablo"*/}
                                        {/*    target="_blank"*/}
                                        {/*    color="transparent"*/}
                                        {/*    onClick={e => e.preventDefault()}*/}
                                        {/*  >*/}
                                        {/*    <i className={"fab fa-google-plus-g"} />*/}
                                        {/*  </Button>*/}
                                        {/*</div>*/}
                                    </CardHeader>
                                    {/*<p className={classes.divider}>Or Be Classical</p>*/}
                                    <CardBody>
                                        {/*<CustomInput*/}
                                        {/*  labelText="First Name..."*/}
                                        {/*  id="first"*/}
                                        {/*  formControlProps={{*/}
                                        {/*    fullWidth: true*/}
                                        {/*  }}*/}
                                        {/*  inputProps={{*/}
                                        {/*    type: "text",*/}
                                        {/*    endAdornment: (*/}
                                        {/*      <InputAdornment position="end">*/}
                                        {/*        <People className={classes.inputIconsColor} />*/}
                                        {/*      </InputAdornment>*/}
                                        {/*    )*/}
                                        {/*  }}*/}
                                        {/*/>*/}
                                        <CustomInput
                                            labelText="Email..."
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "email",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Email className={classes.inputIconsColor}/>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Password"
                                            id="pass"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "password",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon className={classes.inputIconsColor}>
                                                            lock_outline
                                                        </Icon>
                                                    </InputAdornment>
                                                ),
                                                autoComplete: "off"
                                            }}
                                        />
                                    </CardBody>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button simple color="primary" size="lg">
                                            Login
                                        </Button>
                                    </CardFooter>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <Footer whiteFont/>
            </div>
        </div>
    );
}
